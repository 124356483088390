<template>
  <div>
    <title>SIM INVENTORY ~ PRODUCTION SCHEDULE UPDATE</title>
    <section class="content-header">
      <h1>
        Production Schedule Update
        <br />
        <h4>Please edit the supplier master data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Production Schedule Update</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Production Schedule Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Order Number</label>
                  <input
                    type="text"
                    v-model="ordernumber"
                    readonly
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    v-model="idxjadwalproduksi"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Order Date</label>
                  <input
                    type="text"
                    readonly
                    v-model="tglorder"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Start Date Prodcution</label>
                  <input
                    type="date"
                    v-model="mulai"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">End Date Prodcution</label>
                  <input
                    type="date"
                    v-model="selesai"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/jadwal-produksi">
                  <button class="btn btn-success">
                    <i class="fa fa-angle-double-left"></i> Back
                  </button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "editmasterdata",
  data() {
    return {
      isInputActive: false,
      loading: false,
      ordernumber: "",
      idxjadwalproduksi: "",
      tglorder: "",
      mulai: "",
      selesai: ""
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");

      const urlAPIget =
        this.$apiurl +
        "buyer_order/getbuyer_orderbyid?id=" +
        this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.idxjadwalproduksi = resp.data.data.id;
          this.ordernumber = resp.data.data.order_number;
          this.tglorder = resp.data.data.tanggal_order;
          this.mulai = resp.data.data.tanggal_produksi_start;
          this.selesai = resp.data.data.tanggal_produksi_end;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      const paramdata = {
        tanggal_produksi_start: this.mulai,
        tanggal_produksi_end: this.selesai
      };
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIUpdate =
        this.$apiurl +
        "buyer_order/updatebuyer_order_start_end_date/" +
        this.idxjadwalproduksi;

      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .put(urlAPIUpdate, paramdata, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.status == true) {
            swal({
              icon: "success",
              title: "Success",
              text: "Update data successfully",
              showConfirmButton: false
            });
            this.loading = false;
            this.$router.push({ name: "jadwalproduksi" });
          } else {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Update data failed",
              showConfirmButton: false
            });
            this.loading = false;
          }
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
            showConfirmButton: false
          });
          console.log(err);
          this.loading = false;
        });
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
